.fc .fc-scrollgrid-section-sticky > * {
  background-color: var(--kt-body-bg) !important;
}
.gantt-wrapper .calendar rect,
.gantt-wrapper .grid .gridBody .rows rect {
  fill: var(--kt-body-bg);
}
.gantt-wrapper .calendar text {
  fill: var(--kt-text-gray-900);
}
.gantt-wrapper .grid .gridBody .rows rect:nth-child(even) {
  fill: var(--kt-gray-200);
}
.gantt-wrapper .calendar rect,
.gantt-wrapper .grid .gridBody .rowLines line,
.gantt-wrapper .grid .gridBody .ticks line {
  stroke: var(--kt-gray-300);
  stroke-width: 1.4;
}
.gantt-wrapper .handleGroup > g {
  height: 36px;
  display: block;
  overflow: hidden;
}

.ml-5{
  margin-left: 5px;
}
@media (min-width: 375px) {
  #search {
    width: 275px;
  }
}

@media (min-width: 425px) {
  #new_folder {
    width: 150px;
  }
}

@media (min-width: 320px) {
  #combo-box-demo {
    width: 300px;
  }
}

[data-theme='dark'] .ck.ck-editor__main .ck-editor__editable {
  background: #262630;
}

[data-theme='dark'] .css-segi59 {
  color: #fff;
}

[data-theme='dark'] .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  color: #fff;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

[data-theme='dark'] #asynchronous-demo {
  color: #fff;
}

#custom-image{
width: 100%;
height: 200px;  
object-fit: cover;
}

@media (max-width: 1024px) {
  #custom-image {
    height: 100px;
  }
}

.project-richtext-container .ck-content {
  min-height: 550px;
}

.project-ckeditor-container .ck-content {
  min-height: 200px;
}

.select {
  position: relative;
  display: block;
  width: 140px;
}

.options {
  display: none;
  position: absolute;
  background-color: var(--kt-body-bg);
  z-index: 1;
  margin: 8px -10px;
  appearance: none;
  width: 100%;
  border: 1px solid var(--kt-gray-300);
}

.option {
  margin-left: -10px;
  padding-left: 10px;
}

.option:hover {
  background-color: #2977ff;
  color: #fff;
}

[data-theme='light'] .alert-warning {
  color: #000;
}

.milestone figure {
  width: 50% !important;
  height: 50% !important;
}

@media only screen and (max-width: 600px) {
  .milestone figure {
    width: 100% !important;
    height: 100% !important;
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
