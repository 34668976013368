//
// Form controls
//

.form-control {
	color: var(--kt-input-color);
	background-color: var(--kt-input-bg);
  border: $input-border-width solid var(--kt-input-border-color);
	box-shadow: none;
  //@include box-shadow(var(--kt-input-box-shadow));

	&:focus {
	  color: var(--kt-input-focus-color);
	  background-color: var(--kt-input-focus-bg);
	  border-color: var(--kt-input-focus-border-color);
	  // box-shadow: var(--kt-input-focus-box-shadow);
	}

	// Placeholder
	&::placeholder {
	  color: var(--kt-input-placeholder-color);
	}

  &.error {
	  animation: shake 0.2s ease-in-out 0s 2;
	  box-shadow: 0 0 0.5em red;
  }

	// Disabled and read-only inputs
	&:disabled,
	&[readonly] {
	  color: var(--kt-input-disabled-color);
	  background-color: var(--kt-input-disabled-bg);
	  border-color: var(--kt-input-disabled-border-color);
	}

	// File input buttons theming
	&::file-selector-button {
	  color: var(--kt-form-file-button-color);
	  @include gradient-bg(var(--kt-form-file-button-bg));
	}

	&:hover:not(:disabled):not([readonly])::file-selector-button {
	  background-color: var(--kt-form-file-button-hover-bg);
	}
}

// Readonly controls as plain text
.form-control-plaintext {
	color: var(--kt-input-plaintext-color);
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}