//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Typography
//
// Font, line-height, and color for body text, headings, and more.
// Font family
$font-family-sans-serif: Roboto, Helvetica, "sans-serif" !default;

// Bootstrap color system
$white:       #ffffff;
$white-dark:  #2B2B40;
$blue-light:  #03A9F4;
$cyan:        #0dcaf0;


// Bootstrap grey colors
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #D9D9D9 !default;
$gray-400: #B5B5C3 !default;
$gray-500: #A1A5B7 !default;
$gray-600: #837C7C !default;
$gray-700: #5E6278 !default;
$gray-800: #3F4254 !default;
$gray-900: #181C32 !default;

$gray-100-dark:  #212132 !default;
$gray-200-dark:  #2B2B40 !default;
$gray-300-dark:  #323248 !default;
$gray-400-dark:  #474761 !default;
$gray-500-dark:  #565674 !default;
$gray-600-dark:  #6D6D80 !default;
$gray-700-dark:  #92929F !default;
$gray-800-dark:  #CDCDDE !default;
$gray-900-dark:  #FFFFFF !default;

$blue-light-100: #B3E5FC;
$blue-light-200: #81D4FA;
$blue-light-300: #4FC3F7;
$blue-light-400: #29B6F6;
$blue-light-500: $blue-light;
$blue-light-600: #039BE5;
$blue-light-700: #0288D1;
$blue-light-800: #0277BD;
$blue-light-900: #01579B;

$cyan-100: #cff4fc;
$cyan-200: #9eeaf9;
$cyan-300: #6edff6;
$cyan-400: #3dd5f3;
$cyan-500: $cyan;
$cyan-600: #0aa2c0;
$cyan-700: #087990;
$cyan-800: #055160;
$cyan-900: #032830;

$blues-light: (
  "100": $blue-light-100,
  "200": $blue-light-200,
  "300": $blue-light-300,
  "400": $blue-light-400,
  "500": $blue-light-500,
  "600": $blue-light-600,
  "700": $blue-light-700,
  "800": $blue-light-800,
  "900": $blue-light-900
);

$cyans: (
  "100": $cyan-100,
  "200": $cyan-200,
  "300": $cyan-300,
  "400": $cyan-400,
  "500": $cyan-500,
  "600": $cyan-600,
  "700": $cyan-700,
  "800": $cyan-800,
  "900": $cyan-900
);

// Bootstrap muted color
$text-muted: $gray-500 !default;
$text-muted-dark: $gray-500-dark !default;

// Theme colors
// Primary
$primary:       									#009ef7;
$primary-active:    								#0095e8;
$primary-light:    								    #add0e3;
$primary-light-dark:    							#152368;
$primary-inverse:  									$white;

// Secondary colors
$secondary: 					$gray-300 !default;
$secondary-dark: 				$gray-300-dark !default;
$secondary-active: 				$gray-400 !default;
$secondary-active-dark: 		$gray-400-dark !default;
$secondary-light: 				$gray-100 !default;
$secondary-light-dark: 			$gray-100-dark !default;
$secondary-inverse:				$gray-800 !default;
$secondary-inverse-dark:		$gray-800-dark !default;

// Light colors
$light: 						$gray-100 !default;
$light-dark: 					$gray-100-dark !default; 
$light-active: 					$gray-200 !default;
$light-active-dark: 			$gray-300-dark !default;
$light-light: 					rgba($gray-100, 0.75) !default;
$light-inverse:					$gray-600 !default;
$light-inverse-dark: 			$gray-600-dark !default;

// Success
$success:       									#50cd89;
$success-active:    								#47be7d;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    #f1416c;
$danger-active:    									#d9214e;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									#ffc700;
$warning-active:    								#f1bc00;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;

// Dark colors
$dark: 							$gray-900 !default;
$dark-dark: 					$gray-900-dark !default; 
$dark-active: 					darken($gray-900, 3%) !default;
$dark-active-dark: 				lighten($gray-900-dark, 3%) !default;
$dark-light: 					$gray-200 !default; 
$dark-light-dark: 				$gray-200-dark !default;
$dark-inverse: 					$white !default;
$dark-inverse-dark: 			$gray-100-dark !default; 

// Text colors
$theme-text-colors: (
	"white":   $white,
	"primary": $primary,
	"secondary":  $secondary,
	"light":   $light,
	"success": $success,
	"info":    $info,
	"warning": $warning,
	"danger":  $danger,
	"dark":    $dark,
	"muted":   $text-muted,
	"gray-100":   $gray-100,
	"gray-200":   $gray-200,
	"gray-300":   $gray-300,
	"gray-400":   $gray-400,
	"gray-500":   $gray-500,
	"gray-600":   $gray-600,
	"gray-700":   $gray-700,
	"gray-800":   $gray-800,
	"gray-900":   $gray-900,
    "cyan": $cyan
) !default; 

//Btn
$btn-border-radius:                                 0.3rem;

// Links
//
// Style anchor elements.
$link-color:                                        #A385F8;

//Inputs
$input-padding-x:                                   0.5rem;
$border-color:                                      $gray-300;
$border-radius:                                     .2rem;
$input-solid-bg:                                    $gray-300;
$form-check-input-checked-border-color:             $primary;

//Collapse
$transition-collapse:         height 1s ease;

// Keenthemes hight and width sizes
$custom-sizes: (
	unset: unset,
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%,
	auto: auto,
	1px: 1px,
	2px: 2px,
	3px: 3px,
	4px: 4px,
	5px: 5px,
	6px: 6px,
	7px: 7px,
	8px: 8px,
	9px: 9px,
	10px: 10px,
	15px: 15px,
	20px: 20px,
	25px: 25px,
	30px: 30px,
	35px: 35px,
	40px: 40px,
	45px: 45px,
	50px: 50px,
	55px: 55px,
	60px: 60px,
	65px: 65px,
	70px: 70px,
	75px: 75px,
	80px: 80px,
	85px: 85px,
	90px: 90px,
	95px: 95px,
	100px: 100px,
	125px: 125px,
  	140px: 140px,
	150px: 150px,
	175px: 175px,
	200px: 200px,
	210px: 210px,
	225px: 225px,
	250px: 250px,
	275px: 275px,
	300px: 300px,
	325px: 325px,
	350px: 350px,
	375px: 375px,
	400px: 400px,
	425px: 425px,
	450px: 450px,
	475px: 475px,
	500px: 500px,
	550px: 550px,
	600px: 600px,
	650px: 650px,
	700px: 700px,
	750px: 750px,
	800px: 800px,
	850px: 850px,
	900px: 900px,
	950px: 950px,
	1000px: 1000px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1120px,
  xxl: 1600px
);

// Position
//
// Define the edge positioning anchors of the position utilities.
$position-values: (
	0: 0,
	3: 3%,
	5: 5%,
	10: 10%,
	25: 25%,
	50: 50%,
	75: 75%,
	100: 100%
);